<template>

    <div class="widget-body">

<!--        <div id="wizard" class="wizard" data-target="#wizard-steps">-->
<!--            <ul class="steps">-->
<!--                <li data-target="#step-1" class="active"><span class="step">1</span><span class="lrlg">基本配置</span><span class="chevron"></span></li>-->
<!--                <li data-target="#step-2"><span class="step">2</span><span class="lrlg">权限设置</span><span class="chevron"></span></li>-->
<!--                <li data-target="#step-3"><span class="step">3</span><span class="lrlg">模板设计</span><span class="chevron"></span></li>-->
<!--            </ul>-->
<!--        </div>-->
<!--        <div class="step-content" id="wizard-steps">-->
<!--            <div class="step-pane step-pane-base active" id="step-1">-->
<!--                <div class="lr-alert">-->
<!--                    <div class="alert alert-danger">-->
<!--                        <i class="fa fa-warning alert-dismissible"></i>-->
<!--                        <span class="lrlg">请你填写模板信息，用于创建或修改模板</span>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="panel panel-default lr-panel">-->
<!--                    <div class="panel-heading">-->
<!--                        <h3 class="panel-title"><span class="lrlg">模板基本信息配置</span></h3>-->
<!--                    </div>-->
<!--                    <div class="panel-body">-->
<!--                        <input id="F_SchemeId" type="hidden" />-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:15px;">-->
<!--                            <div class="lr-form-item-title">模板编号<font face="宋体">*</font></div>-->
<!--                            <input id="F_Code" type="text" class="form-control" placeholder="请输入编号" isvalid="yes" checkexpession="EnglishNumStr" />-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:53px;">-->
<!--                            <div class="lr-form-item-title">模板名称<font face="宋体">*</font></div>-->
<!--                            <input id="F_Name" type="text" class="form-control" placeholder="请输入名称" isvalid="yes" checkexpession="NotNull" />-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:91px;">-->
<!--                            <div class="lr-form-item-title">模板分类<font face="宋体">*</font></div>-->
<!--                            <div id="F_Category" isvalid="yes" checkexpession="NotNull"></div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:129px;">-->
<!--                            <div class="lr-form-item-title">我的任务<font face="宋体">*</font></div>-->
<!--                            <div class="radio lr-radio" type="lr-radio" id="F_Mark">-->
<!--                                <label>-->
<!--                                    <input name="F_Mark" type="radio" value="1" checked="checked" />-->
<!--                                    <span class="lrlg">允许发起</span>-->
<!--                                </label>-->
<!--                                <label>-->
<!--                                    <input name="F_Mark" type="radio" value="2" />-->
<!--                                    <span class="lrlg">不允许发起</span>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:167px;">-->
<!--                            <div class="lr-form-item-title">移动端<font face="宋体">*</font></div>-->
<!--                            <div class="radio lr-radio" type="lr-radio" id="F_IsInApp">-->
<!--                                <label>-->
<!--                                    <input name="F_IsInApp" type="radio" value="1" />-->
<!--                                    <span class="lrlg">显示</span>-->
<!--                                </label>-->
<!--                                <label>-->
<!--                                    <input name="F_IsInApp" type="radio" value="2" checked="checked" />-->
<!--                                    <span class="lrlg">不显示</span>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:205px;">-->
<!--                            <div class="lr-form-item-title">撤销作废执行<font face="宋体">*</font></div>-->
<!--                            <div class="radio lr-radio" type="lr-radio" id="F_CloseDoType">-->
<!--                                <label>-->
<!--                                    <input name="closeDoType" type="radio" value="sql" checked="checked" />-->
<!--                                    <span class="lrlg">sql</span>-->
<!--                                </label>-->
<!--                                <label>-->
<!--                                    <input name="closeDoType" type="radio" value="ioc" />-->
<!--                                    <span class="lrlg">依赖注入方法</span>-->
<!--                                </label>-->
<!--                                <label>-->
<!--                                    <input name="closeDoType" type="radio" value="interface" />-->
<!--                                    <span class="lrlg">接口</span>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-12 lr-form-item" style="top:243px;height:140px;">-->
<!--                            <div id="sql" class="operationDiv" style="position:relative;width:100%;height:100%;padding-top:52px;">-->
<!--                                <div style="position:absolute;height:49px;width:100%;padding:0px;top:0;left:0;">-->
<!--                                    <div class="alert alert-danger" style="position:relative;text-align: left;margin:0;">-->
<!--                                        <i class="fa fa-question-circle alert-dismissible" style="position: relative; top: 1px; font-size: 15px; padding-right: 5px;"></i>-->
<!--                                        <span class="lrlg">注意：请配置SQL语句（{processId}流程进程主键{userId}用户Id{userAccount}用户账号{companyId}用户公司{departmentId}用户部门{code}1撤销2作废3删除草稿）！</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="lr-form-wrap" style="padding:0px;padding-top:40px;">-->
<!--                                    <div style="position:absolute;left:0;top:5px;width:100%;height:28px;padding:0;"><div id="F_CloseDoDbId" style="position:relative;width:100%;height:100%;"></div></div>-->
<!--                                    <textarea id="F_CloseDoSql" class="form-control" style="height: 100%" placeholder="请填写Sql语句"></textarea>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div id="ioc" class="operationDiv" style="position:relative;width:100%;height:100%;display:none;padding-top:54px;">-->
<!--                                <div style="position:absolute;height:54px;width:100%;padding:0px;top:0;left:0;">-->
<!--                                    <div class="alert alert-danger" style="position:relative;text-align: left;margin:0;">-->
<!--                                        <i class="fa fa-question-circle alert-dismissible" style="position: relative; top: 1px; font-size: 15px; padding-right: 5px;"></i>-->
<!--                                        注意：必须要开发人员指导下进行配置注册的IOC方法名,需要继承接口IWorkFlowMethod(或咨询力软售后人员)！-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="lr-form-wrap" style="padding:0px;">-->
<!--                                    <textarea id="F_CloseDoIocName" class="form-control" placeholder="请填写方法名" style="height: 100%"></textarea>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div id="interface" class="operationDiv" style="position:relative;width:100%;height:100%;display:none;padding-top:74px;">-->
<!--                                <div style="position:absolute;height:54px;width:100%;padding:0px;top:0;left:0;">-->
<!--                                    <div class="alert alert-danger" style="position:relative;text-align: left;margin:0;">-->
<!--                                        <i class="fa fa-question-circle alert-dismissible" style="position: relative; top: 1px; font-size: 15px; padding-right: 5px;"></i>-->
<!--                                        <span class="lrlg">注意：必须要开发人员指导下进行配置支持Post方法的接口,json数据格式。{processId:'流程发起实例主键',userId:'用户Id',userAccount:'用户账号',companyId:'用户公司',departmentId:'用户部门',code:'1撤销2作废'}(或咨询力软售后人员)！</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="lr-form-wrap" style="padding:0px;">-->
<!--                                    <textarea id="F_CloseDoInterface" class="form-control" placeholder="请填写接口地址" style="height: 100%"></textarea>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="col-xs-12 lr-form-item" style="position:relative;width:100%;height:100%;">-->
<!--                            <div class="lr-form-item-title">备注</div>-->
<!--                            <textarea id="F_Description" class="form-control" style="height: 100%;"></textarea>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="step-pane" id="step-2" style="padding:5px;padding-top:40px;">-->
<!--                <div style="position:absolute;height:40px;width:100%;left:0;top:0;">-->
<!--                    <div class="col-xs-12 lr-form-item" style="padding:0px;padding-left:10px;padding-top: 8px;">-->
<!--                        <div class="radio">-->
<!--                            <label>-->
<!--                                <input name="authorizeType" id="authorizeType1" type="radio" value="1" checked="checked" />-->
<!--                                <span class="lrlg">所有成员</span>-->
<!--                            </label>-->
<!--                            <label>-->
<!--                                <input name="authorizeType" id="authorizeType2" type="radio" value="2" />-->
<!--                                <span class="lrlg">指定成员</span>-->
<!--                            </label>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--                <div style="position:relative;width:100%;height:100%;border:1px solid #ddd;padding-bottom:30px;">-->
<!--                    <div style="bottom:0;border-bottom:0px;border-top:1px solid #ddd;position: absolute;left: 0;width: 100%;height: 30px;line-height: 29px;background-color: #F5F5F5;border-bottom: 1px solid #ddd;text-align: right;padding-right: 10px;font-weight: bold;color: #676a6c;">-->
<!--                        <div class="lr-form-jfgrid-btns">-->
<!--                            <a id="lr_post_authorize" class="btn btn-success"><i class="fa fa-graduation-cap"></i>&nbsp; <span class="lrlg">添加岗位</span></a>-->
<!--                            <a id="lr_role_authorize" class="btn btn-info"><i class="fa fa-paw"></i>&nbsp; <span class="lrlg">添加角色</span></a>-->
<!--                            <a id="lr_user_authorize" class="btn btn-primary"><i class="fa fa-user"></i>&nbsp; <span class="lrlg">添加人员</span></a>-->

<!--                            <a id="lr_delete_authorize" class="btn btn-danger"><i class="fa fa-trash-o"></i>&nbsp; <span class="lrlg">移除</span></a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div id="authorize_girdtable"></div>-->
<!--                    <div id="shcemeAuthorizeBg" style="z-index:10;position:absolute;left:0;top:0;width:100%;height:100%;background:#000;filter:alpha(opacity=10); opacity: 0.1;"></div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="step-pane" id="step-3"> </div>
<!--        </div>-->

        <div class="lr-form-bottom-button" id="wizard-actions">
            <a id="btn_prev" class="btn btn-default btn-prev" disabled><span class="lrlg">上一步</span></a>
            <a id="btn_next" class="btn btn-default btn-next"><span class="lrlg">下一步</span></a>
            <a id="btn_draft" class="btn btn-warning"><span class="lrlg">保存草稿</span></a>
            <a id="btn_finish" class="btn btn-success" disabled><span class="lrlg">保存</span></a>
        </div>
    </div>

</template>

<script>
  import '@/shared/workflow/zr-workflow-ui.js'

    export default {
        name: "designer.vue",
        created(){
        },
        mounted() {       // 设计页面初始化
            console.log('created!!!')
            console.log()
            $('#step-3').lrworkflow({
                openNode: function (node, nodelist) {
                    // currentNode = node;
                    // nodeList = nodelist;
                    // if (node.type != 'endround') {
                    //     learun.layerForm({
                    //         id: 'NodeForm',
                    //         title: '节点信息设置【' + node.name + '】',
                    //         url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/NodeForm?layerId=layer_Form',
                    //         width: 700,
                    //         height: 550,
                    //         callBack: function (id) {
                    //             return top[id].acceptClick(function () {
                    //                 $('#step-3').lrworkflowSet('updateNodeName', { nodeId: currentNode.id });
                    //             });
                    //         }
                    //     });
                    // }
                },
                openLine: function (line, node) {
                    // currentLine = line;
                    // fromNode = node;
                    // learun.layerForm({
                    //     id: 'LineForm',
                    //     title: '线条信息设置',
                    //     url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/LineForm?layerId=layer_Form',
                    //     width: 700,
                    //     height: 550,
                    //     callBack: function (id) {
                    //         return top[id].acceptClick(function () {
                    //             $('#step-3').lrworkflowSet('updateLineName', { lineId: currentLine.id });
                    //         });
                    //     }
                    // });
                }
            });

        }
    }
</script>






<!--<script>-->

<!--     function request(){-->
<!--          return ''-->
<!--     }-->
<!--    var keyValue = request('keyValue');-->
<!--    var categoryId = request('categoryId');-->
<!--    var shcemeCode = request('shcemeCode');-->

<!--    var currentNode; // 当前设置节点-->
<!--    var nodeList;    // 流程节点-->
<!--    var fromNode;-->
<!--    var currentLine; // 当前设置线条-->
<!--    var schemeAuthorizes = []; // 模板权限人员-->
<!--    var authorizeType = 1;// 模板权限类型-->

<!--    var bootstrap = function ($, learun) {-->
<!--        "use strict";-->

<!--        function isRepeat(id) {-->
<!--            var res = false;-->
<!--            for (var i = 0, l = schemeAuthorizes.length; i < l; i++) {-->
<!--                if (schemeAuthorizes[i].F_ObjectId == id) {-->
<!--                    learun.alert.warning('重复添加信息');-->
<!--                    res = true;-->
<!--                    break;-->
<!--                }-->
<!--            }-->
<!--            return res;-->
<!--        }-->

<!--        var page = {-->
<!--            init: function () {-->
<!--                page.bind();-->
<!--                page.initData();-->
<!--            },-->
<!--            /*绑定事件和初始化控件*/-->
<!--            bind: function () {-->
<!--                // 加载导向-->
<!--                $('#wizard').wizard().on('change', function (e, data) {-->
<!--                    var $finish = $("#btn_finish");-->
<!--                    var $next = $("#btn_next");-->
<!--                    if (data.direction == "next") {-->
<!--                        if (data.step == 1) {-->
<!--                            if (!$('#step-1').lrValidform()) {-->
<!--                                return false;-->
<!--                            }-->
<!--                        }-->
<!--                        else if (data.step == 2) {-->
<!--                            if (authorizeType != 1) {-->
<!--                                if (schemeAuthorizes.length == 0) {-->
<!--                                    learun.alert.error('请添加权限人员信息');-->
<!--                                    return false;-->
<!--                                }-->
<!--                            }-->
<!--                            $finish.removeAttr('disabled');-->
<!--                            $next.attr('disabled', 'disabled');-->
<!--                        }-->
<!--                        else {-->

<!--                            $finish.attr('disabled', 'disabled');-->
<!--                        }-->
<!--                    } else {-->
<!--                        $finish.attr('disabled', 'disabled');-->
<!--                        $next.removeAttr('disabled');-->
<!--                    }-->
<!--                });-->
<!--                // 流程分类-->
<!--                $('#F_Category').lrDataItemSelect({ code: 'FlowSort' });-->
<!--                $('#F_Category').lrselectSet(categoryId);-->
<!--                $('input[name="closeDoType"]').on('click', function () {-->
<!--                    var value = $(this).val();-->
<!--                    $('.operationDiv').hide();-->
<!--                    $('#' + value).show();-->
<!--                });-->
<!--                $('#F_CloseDoDbId').lrselect({-->
<!--                    url: top.$.rootUrl + '/LR_SystemModule/DatabaseLink/GetTreeList',-->
<!--                    type: 'tree',-->
<!--                    placeholder: '请选择数据库',-->
<!--                    allowSearch: true-->
<!--                });-->


<!--                // 权限设置-->
<!--                $('input[name="authorizeType"]').on('click', function () {-->
<!--                    var $this = $(this);-->
<!--                    var value = $this.val();-->
<!--                    authorizeType = value;-->
<!--                    if (value == '1') {-->
<!--                        $('#shcemeAuthorizeBg').show();-->
<!--                    }-->
<!--                    else {-->
<!--                        $('#shcemeAuthorizeBg').hide();-->
<!--                    }-->
<!--                });-->
<!--                $('#authorize_girdtable').jfGrid({-->
<!--                    headData: [-->
<!--                        {-->
<!--                            label: "类型", name: "F_ObjType", width: 100, align: "center",-->
<!--                            formatter: function (cellvalue) {//审核者类型1.岗位2.角色3.用户-->
<!--                                switch (parseInt(cellvalue)) {-->
<!--                                    case 1:-->
<!--                                        return '岗位';-->
<!--                                        break;-->
<!--                                    case 2:-->
<!--                                        return '角色';-->
<!--                                        break;-->
<!--                                    case 3:-->
<!--                                        return '用户';-->
<!--                                        break;-->
<!--                                }-->
<!--                            }-->
<!--                        },-->
<!--                        { label: "名称", name: "F_ObjName", width: 700, align: "left" }-->
<!--                    ]-->
<!--                });-->



<!--                // 岗位添加-->
<!--                $('#lr_post_authorize').on('click', function () {-->
<!--                    learun.layerForm({-->
<!--                        id: 'AuthorizePostForm',-->
<!--                        title: '添加岗位',-->
<!--                        url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/PostForm?flag=1',-->
<!--                        width: 400,-->
<!--                        height: 300,-->
<!--                        callBack: function (id) {-->
<!--                            return top[id].acceptClick(function (data) {-->
<!--                                if (!isRepeat(data.auditorId)) {-->
<!--                                    var _data = {};-->
<!--                                    _data.F_Id = learun.newGuid();-->
<!--                                    _data.F_ObjName = data.auditorName;-->
<!--                                    _data.F_ObjId = data.auditorId;-->
<!--                                    _data.F_ObjType = data.type;-->
<!--                                    schemeAuthorizes.push(_data);-->
<!--                                    $('#authorize_girdtable').jfGridSet('refreshdata', { rowdatas: schemeAuthorizes });-->
<!--                                }-->
<!--                            });-->
<!--                        }-->
<!--                    });-->
<!--                });-->
<!--                // 角色添加-->
<!--                $('#lr_role_authorize').on('click', function () {-->
<!--                    learun.layerForm({-->
<!--                        id: 'AuthorizeRoleForm',-->
<!--                        title: '添加角色',-->
<!--                        url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/RoleForm?flag=1',-->
<!--                        width: 400,-->
<!--                        height: 300,-->
<!--                        callBack: function (id) {-->
<!--                            return top[id].acceptClick(function (data) {-->
<!--                                if (!isRepeat(data.auditorId)) {-->
<!--                                    var _data = {};-->
<!--                                    _data.F_Id = learun.newGuid();-->
<!--                                    _data.F_ObjName = data.auditorName;-->
<!--                                    _data.F_ObjId = data.auditorId;-->
<!--                                    _data.F_ObjType = data.type;-->
<!--                                    schemeAuthorizes.push(_data);-->
<!--                                    $('#authorize_girdtable').jfGridSet('refreshdata', { rowdatas: schemeAuthorizes });-->
<!--                                }-->
<!--                            });-->
<!--                        }-->
<!--                    });-->
<!--                });-->
<!--                // 人员添加-->
<!--                $('#lr_user_authorize').on('click', function () {-->
<!--                    learun.layerForm({-->
<!--                        id: 'AuthorizeUserForm',-->
<!--                        title: '添加人员',-->
<!--                        url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/UserForm',-->
<!--                        width: 400,-->
<!--                        height: 300,-->
<!--                        callBack: function (id) {-->
<!--                            return top[id].acceptClick(function (data) {-->
<!--                                if (!isRepeat(data.auditorId)) {-->
<!--                                    var _data = {};-->
<!--                                    _data.F_Id = learun.newGuid();-->
<!--                                    _data.F_ObjName = data.auditorName;-->
<!--                                    _data.F_ObjId = data.auditorId;-->
<!--                                    _data.F_ObjType = data.type;-->
<!--                                    schemeAuthorizes.push(_data);-->
<!--                                    $('#authorize_girdtable').jfGridSet('refreshdata', { rowdatas: schemeAuthorizes });-->
<!--                                }-->
<!--                            });-->
<!--                        }-->
<!--                    });-->
<!--                });-->
<!--                // 人员移除-->
<!--                $('#lr_delete_authorize').on('click', function () {-->
<!--                    var _id = $('#authorize_girdtable').jfGridValue('F_Id');-->
<!--                    if (learun.checkrow(_id)) {-->
<!--                        learun.layerConfirm('是否确认删除该项！', function (res, index) {-->
<!--                            if (res) {-->
<!--                                for (var i = 0, l = schemeAuthorizes.length; i < l; i++) {-->
<!--                                    if (schemeAuthorizes[i].F_Id == _id) {-->
<!--                                        schemeAuthorizes.splice(i, 1);-->
<!--                                        $('#authorize_girdtable').jfGridSet('refreshdata', { rowdatas: schemeAuthorizes });-->
<!--                                        break;-->
<!--                                    }-->
<!--                                }-->
<!--                                top.layer.close(index); //再执行关闭-->
<!--                            }-->
<!--                        });-->
<!--                    }-->
<!--                });-->


<!--                // 设计页面初始化-->
<!--                $('#step-3').lrworkflow({-->
<!--                    openNode: function (node, nodelist) {-->
<!--                        currentNode = node;-->
<!--                        nodeList = nodelist;-->
<!--                        if (node.type != 'endround') {-->
<!--                            learun.layerForm({-->
<!--                                id: 'NodeForm',-->
<!--                                title: '节点信息设置【' + node.name + '】',-->
<!--                                url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/NodeForm?layerId=layer_Form',-->
<!--                                width: 700,-->
<!--                                height: 550,-->
<!--                                callBack: function (id) {-->
<!--                                    return top[id].acceptClick(function () {-->
<!--                                        $('#step-3').lrworkflowSet('updateNodeName', { nodeId: currentNode.id });-->
<!--                                    });-->
<!--                                }-->
<!--                            });-->
<!--                        }-->
<!--                    },-->
<!--                    openLine: function (line, node) {-->
<!--                        currentLine = line;-->
<!--                        fromNode = node;-->
<!--                        learun.layerForm({-->
<!--                            id: 'LineForm',-->
<!--                            title: '线条信息设置',-->
<!--                            url: top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/LineForm?layerId=layer_Form',-->
<!--                            width: 700,-->
<!--                            height: 550,-->
<!--                            callBack: function (id) {-->
<!--                                return top[id].acceptClick(function () {-->
<!--                                    $('#step-3').lrworkflowSet('updateLineName', { lineId: currentLine.id });-->
<!--                                });-->
<!--                            }-->
<!--                        });-->
<!--                    }-->
<!--                });-->
<!--                // 保存草稿-->
<!--                $("#btn_draft").on('click', page.draftsave);-->
<!--                // 保存数据按钮-->
<!--                $("#btn_finish").on('click', page.save);-->
<!--            },-->
<!--            /*初始化数据*/-->
<!--            initData: function () {-->
<!--                if (!!shcemeCode) {-->
<!--                    $.lrSetForm(top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/GetFormData?code=' + shcemeCode, function (data) {//-->
<!--                        $('#step-1').lrSetFormData(data.info);-->
<!--                        var shceme = JSON.parse(data.scheme.F_Content);-->
<!--                        shceme.closeDo = shceme.closeDo || {};-->

<!--                        $('#step-1').lrSetFormData(shceme.closeDo);-->
<!--                        $('#step-3').lrworkflowSet('set', { data: shceme });-->

<!--                        if (data.authList.length > 0 && data.authList[0].F_ObjType != 4) {-->
<!--                            $('#authorizeType2').trigger('click');-->
<!--                            schemeAuthorizes = data.authList;-->
<!--                            $('#authorize_girdtable').jfGridSet('refreshdata', { rowdatas: schemeAuthorizes });-->
<!--                            authorizeType = 2;-->
<!--                        }-->
<!--                    });-->
<!--                }-->
<!--            },-->
<!--            /*保存草稿*/-->
<!--            draftsave: function () {-->
<!--                var formdata = $('#step-1').lrGetFormData(keyValue);-->
<!--                var shcemeData = $('#step-3').lrworkflowGet();-->

<!--                if (formdata.F_CloseDoSql == '&nbsp;') {-->
<!--                    formdata.F_CloseDoSql = '';-->
<!--                }-->
<!--                if (formdata.F_CloseDoIocName == '&nbsp;') {-->
<!--                    formdata.F_CloseDoIocName = '';-->
<!--                }-->
<!--                if (formdata.F_CloseDoInterface == '&nbsp;') {-->
<!--                    formdata.F_CloseDoInterface = '';-->
<!--                }-->

<!--                shcemeData.closeDo = {-->
<!--                    F_CloseDoType: formdata.F_CloseDoType,-->
<!--                    F_CloseDoDbId: formdata.F_CloseDoDbId,-->
<!--                    F_CloseDoSql: formdata.F_CloseDoSql,-->
<!--                    F_CloseDoIocName: formdata.F_CloseDoIocName,-->
<!--                    F_CloseDoInterface: formdata.F_CloseDoInterface,-->
<!--                };-->


<!--                if (authorizeType == 1) {-->
<!--                    schemeAuthorizes = [];-->
<!--                }-->

<!--                var postData = {-->
<!--                    schemeInfo: JSON.stringify(formdata),-->
<!--                    scheme: JSON.stringify(shcemeData),-->
<!--                    shcemeAuth: JSON.stringify(schemeAuthorizes),-->
<!--                    type: 2-->
<!--                };-->

<!--                $.lrSaveForm(top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/SaveForm?keyValue=' + keyValue, postData, function (res) {-->
<!--                    // 保存成功后才回调-->
<!--                    learun.frameTab.currentIframe().refreshGirdData();-->
<!--                });-->
<!--            },-->
<!--            /*保存数据*/-->
<!--            save: function () {-->
<!--                if (!$('#step-1').lrValidform()) {-->
<!--                    return false;-->
<!--                }-->
<!--                var formdata = $('#step-1').lrGetFormData(keyValue);-->
<!--                var shcemeData = $('#step-3').lrworkflowGet();-->

<!--                if (authorizeType == 1) {-->
<!--                    schemeAuthorizes = [];-->
<!--                    schemeAuthorizes.push({-->
<!--                        F_Id: learun.newGuid(),-->
<!--                        F_ObjType: 4-->
<!--                    });-->
<!--                }-->

<!--                if (formdata.F_CloseDoSql == '&nbsp;') {-->
<!--                    formdata.F_CloseDoSql = '';-->
<!--                }-->
<!--                if (formdata.F_CloseDoIocName == '&nbsp;') {-->
<!--                    formdata.F_CloseDoIocName = '';-->
<!--                }-->
<!--                if (formdata.F_CloseDoInterface == '&nbsp;') {-->
<!--                    formdata.F_CloseDoInterface = '';-->
<!--                }-->

<!--                shcemeData.closeDo = {-->
<!--                    F_CloseDoType: formdata.F_CloseDoType,-->
<!--                    F_CloseDoDbId: formdata.F_CloseDoDbId,-->
<!--                    F_CloseDoSql: formdata.F_CloseDoSql,-->
<!--                    F_CloseDoIocName: formdata.F_CloseDoIocName,-->
<!--                    F_CloseDoInterface: formdata.F_CloseDoInterface,-->
<!--                };-->

<!--                var postData = {-->
<!--                    schemeInfo: JSON.stringify(formdata),-->
<!--                    scheme: JSON.stringify(shcemeData),-->
<!--                    shcemeAuth: JSON.stringify(schemeAuthorizes),-->
<!--                    type: 1-->
<!--                };-->




<!--                $.lrSaveForm(top.$.rootUrl + '/LR_NewWorkFlow/NWFScheme/SaveForm?keyValue=' + keyValue, postData, function (res) {-->
<!--                    // 保存成功后才回调-->
<!--                    learun.frameTab.currentIframe().refreshGirdData();-->
<!--                });-->
<!--            }-->
<!--        };-->

<!--        page.init();-->
<!--    }-->
<!--</script>-->

